<template>
  <router-view></router-view>
</template>

<script>
  alert("Facebook");
  window.fbAsyncInit = function() {
    FB.init({
      appId            : '899633625354989',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v21.0'
    });
  };
</script>
<script async defer crossorigin="anonymous"
  src="https://connect.facebook.net/en_US/sdk.js">
</script>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
 