import axios from 'axios';

let baseUrl = "http://api.amecagob.site";

if(document.domain.includes("gobbots.com")){
    baseUrl = "https://api.gobbots.com";
}

axios.defaults.baseURL = baseUrl;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'Application/json';

var host = window.location.host
var subdomain = host.split('.')[0];

if(subdomain !== 'localhost:8080' || subdomain !== 'localhost:8081' || subdomain !== 'localhost:8082'){
    localStorage.setItem("domain", subdomain);
}
else{
    localStorage.removeItem("domain");
}

if (localStorage.getItem('domain') === null || localStorage.getItem('domain') === 'undefined' || localStorage.getItem('domain') === 'localhost:8082' || localStorage.getItem('domain') === 'localhost:8081' || localStorage.getItem('domain') === 'localhost:8080' || localStorage.getItem('domain') === 'localhost') {
    axios.defaults.headers.common['domain'] = 'demo';
} else {
    axios.defaults.headers.common['domain'] = localStorage.getItem('domain');
}

if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'undefined') {
    axios.defaults.headers.common['api-token'] = '';
} else {
    axios.defaults.headers.common['api-token'] = localStorage.getItem('token');
}

axios.interceptors.request.use(function (config) { 
    return config;
}, function (error) {
    return Promise.reject(error);
})

axios.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    if(error.response !== undefined){
        if (error.response.status === 401) {
            console.log(error.response);
        } else if (error.response.status === 500 || error.response.status === 404 || error.response.status === 400) {
            throw error;
        } else {
            return Promise.reject(error);
        }
    }
    else{
        return Promise.reject(error);
    }
})
